import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import ScheduleCard from 'common/ScheduleCard/ScheduleCard';
import Button from '../../components/Buttom/Button';
import IEvent from 'interfaces/IEvent';
import SubTitle from 'common/SubTitle/SubTitle';
import { formatDate } from 'utils/string';

interface Props {
  events?: IEvent[];
}

const Schedule = (props: Props): JSX.Element => {
  const { events } = props;

  return (
    <Container>
      <SubTitle text="Agenda" />
      <ul>
        {events?.slice(0, 4).map(event => {
          return (
            <ScheduleCard
              key={event.id}
              category={event.category}
              dateStr={formatDate(event.date)}
              eventName={event.title}
              img={event.image}
              slug={event.slug ?? ''}
            />
          );
        })}
      </ul>
      <Link to="/what-to-do/events">
        <Button text="ver agenda completa" />
      </Link>
    </Container>
  );
};

export default Schedule;
